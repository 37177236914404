import React from 'react';
import sch1 from './photos/sch1.jpg';
import sch2 from './photos/sch2.jpg';
import sch4 from './photos/sch4.jpg';
import sch5 from './photos/sch5.jpg';
import sch6 from './photos/sch6.jpg';
import sch7 from './photos/sch7.jpg';
import sch8 from './photos/sch8.jpg';
import './events.css';

const Projects = () => {
  const images = [
    {id:1, src: 'sch1'},
    {id:1, src: 'sch2'},
    {id:1, src: 'sch3'},
    {id:1, src: 'sch4'},
    {id:1, src: 'sch5'},
    {id:1, src: 'sch6'},
    {id:1, src: 'sch7'},
    {id:1, src: 'sch8'}
  ]
  const schImages = images.map((image, id) => {
    <img key={id} src={image}></img>
  });
  return (
    <div>
      <h5>Projects Needing Immediate Attention</h5> 
      
        <div>
          <ul className="bldgs">
            <li>
              <div className="bldg"> 
                <img  className="bldg-image" src={sch1}></img>
              </div>
            </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={sch2}></img>
            </div>
          </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={sch8}></img>
            </div>
          </li>
          <li>
            <div className="bldg">
              <img  className="bldg-image" src={sch4}></img>
            </div>
          </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={sch5}></img>
            </div>
          </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={sch6}></img>
            </div>
          </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={sch7}></img>
            </div>
          </li>
          </ul>
        </div>
    </div>
  );
};

export default Projects;

//<img className="images" src={schImages}></img>
