import React from 'react';
import './App.css';
import babaunited from './babaunited.jpg';
import Maple from './maple.jpg';



const Dashboard = () => {
  return (
    <div>
      <div className="header">
        <h4>United We Stand</h4>
      </div>
        <p3>
        The long overdue United 95 set alumni group is finally here, 
        hurray!!! This group of individuals share a common bond that cannot be easily broken. 
        This is why we have decided to step up to the challenges the educational system is facing in Nigeria. 
        Starting from United Grammar School, Ilesa, we can tell that Nigeria's educational system is rotten and demands immediate attention. 
        We shall arise! we shall conquer!! and we shall overcome!!! God's willing!!!!!!!
        </p3>
        <div>
          <img className="images" src={Maple}></img>
        </div>
    </div>
  );
};

export default Dashboard;
