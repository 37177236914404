import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Donate from './Donate';
import DateTime from './DateTime';
//import './css/header.css';
//import '../sass/style.css';

class Header extends Component {

  loginOptions() {
    return (
      <div class="dropdown-button">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Login Here!
      </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <a class="dropdown-item" style={{color:"red"}} href="/auth/google">Login With Google</a>
        </div>
      </div>
    )
  };

  renderContent() {
    switch (this.props.auth) {
      case null:
        return;
      case false:
        return this.loginOptions();
      default:
        return [
          <li key="1"><Donate /></li>,
          <li key="2"><a href="/api/logout">Logout</a></li>
        ];
    }
  }

  render() {
    return (
      <nav class="nav-extended">
        <div className="nav-wrapper red">
        <Link to='/' class="left brand-logo">UNIGRAM 95 Alumnis</Link>
        <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons"></i></a>
      
      {/* <ul class="right">
        {this.renderContent()}
      </ul> */}
    
        </div>
        
        <div class="nav-content-center">
        <div className="dateTime"><DateTime /></div>
      <ul class="tabs tabs-transparent">
        <li><Link to='/about'> About</Link></li>
        <li><Link to='/alumnis'> Meet The Team</Link></li>
        <li><Link to='/events'> Events</Link></li>
        {/* <li><Link to='/events2'> Events2</Link></li> */}
        <li><Link to='/projects'> Projects</Link></li>
      </ul>
    </div>
      </nav>
    );
  }
}
//export default Header;
function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(Header);
//<li><a href="/auth/google">Login With Google</a></li>;
