import React, {Component} from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { Link } from 'react-router-dom';

class Donate extends Component {
  render() {
    return (
      <StripeCheckout
      name="Unigram 95 Set Alumnis"
      description="100% of your donation goes towards rebuilding the school"
      token={token => console.log(token)}
      stripeKey={process.env.REACT_APP_STRIPE_KEY}
      >
        <button className="btn">
          Donate here
        </button>
      </StripeCheckout>
    );
  }
  
};

export default Donate;
