import React from 'react';
import './App.css';

const Footer = () => {
  return (
    <footer class="page-footer red">
      <div className="footer">
      © 2023 - United/Methodist Grammar School, Ilesa, Osun State, Nigeria 95 Set Alumnis - email admin at cyu437@yahoo.com
      </div>
    </footer>
  );
};

export default Footer;
//<a class="grey-text text-lighten-4 right" href="#!"></a>
