import React from 'react';
import PixM from './babaunited.jpg';

const About = () => {
  return (
    <div>
      <h5>Mission</h5>
      <p>To bring awareness to public educational issues in Nigeria through giving back to one's old school
        in form of rebuilding, rewarding teachers, providing resources to students, sponsoring them for higher
        education, and connecting them with the future. By this, we plan to showcase what a united minds can
        achieve, and to encourage others to do the same, thus, lifting Nigeria public educational standards.
      </p>
      <h5>Goal</h5>
      <p>Giving back to a place that laid the foundation for one's life is Noblesse Oblige!</p>
        <div>
          <img className="images" src={PixM}></img>
        </div>
        <h5>Do you really know the story behind this statue called Baba United?</h5> Well, let me tell you.......
        <p>When the United 95 set were in JSS 2, the fine-art teacher, Mr. Ojo asked us to make items such as plates, cups e.t.c. as homework, 
          since he has taught us how to do the same in class with Paper-Mache (starch and paper mixed together with water). He was so impressed with our homework 
          that he suggested that we do something big as a monument for the school - thus this statue idea came up.
          You may not even be aware, the current statue you see today was not the original one. The first one we built, was made out of clothing hangers as skeletons, 
          and the Paper-Mache, then, painted beautifully. No one would have guessed the inferiority of the materials used, until one faithful day when it rained heavily. 
          We came back to school the following day to find our precious statue on the ground - the wind had knocked it down to the ground.
          Sad and disappointed, the school rallied around us to raised funds, which we used to construct a new, 
          iron skeleton statue with the help of a local welder, and Mr. Ojo carefully plastered it with cement. 
          He had one of us Obembe, Gbenga (of a blessed memory) to pose as the image while he carefully modeled it. As you can see, the final product is still standing as of today.
      </p>
       
    </div>
  );
};

export default About;
