import React from 'react';
import reunion21 from './photos/reunion21.jpg';
import reunion22 from './photos/reunion22.jpg';
import reunion222 from './photos/reunion222.jpg';
import reunion23 from './photos/reunion23.jpg';
import reunion232 from './photos/reunion232.jpg';
import './events.css';

const Events = () => {
  const images = [
    {id:1, src: 'reunion21'},
    {id:1, src: 'reunion22'},
    {id:1, src: 'reunion222'},
    {id:1, src: 'reunion23'},
    {id:1, src: 'reunion232'}
  ]
  const schImages = images.map((image, id) => {
    <img key={id} src={image}></img>
  });
  return (
    <div>
      <h5 className='title'>Reunion Party</h5> 
      
        <div>
          <ul className="bldgs">
            <li>
              <div className="bldg"> 
                <img  className="bldg-image" src={reunion21}></img>
                <span>2021</span>
              </div>
            </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={reunion22}></img>
              <span>2022</span>
            </div>
          </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={reunion222}></img>
              <span>2022</span>
            </div>
          </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={reunion23}></img>
              <span>2023</span>
            </div>
          </li>
          <li>
            <div className="bldg">
              <img className="bldg-image" src={reunion232}></img>
              <span>2023</span>
            </div>
          </li>
          </ul>
        </div>
    </div>
  );
};

export default Events;

//<img className="images" src={schImages}></img>
