import React from 'react';
import Tiles from './tiles.PNG';
import Meone from './Me1.jpg';
import bolajoko from './photos/bolajoko.jpg';
import ajiwe from './photos/ajiwe.jpg';
import alabaoyebode from './photos/alabaoyebode.jpg';
import banjoRIP from './photos/banjoRIP.jpg';
import dapoOlayemi from './photos/dapoOlayemi.jpg';
import diji from './photos/diji.jpg';
import dokun from './photos/dokun.jpg';
import fisayoabe from './photos/fisayoabe.jpg';
import funkeaguda from './photos/funkeaguda.jpg';
import idiatAdeyemi from './photos/idiatAdeyemi.jpg';
import idowuabe from './photos/idowuabe.jpg';
import idowuadeleye from './photos/idowuadeleye.jpg';
import ilesanmifolaju from './photos/ilesanmifolaju.jpg';
import janetareola from './photos/janetareola.jpg';
import jongboAdebayo from './photos/jongboAdebayo.jpg';
import kehindeadesina from './photos/kehindeadesina.jpg';
import lekan from './photos/lekan.jpg';
import lekanawe from './photos/lekanawe.jpg';
import segunAwe from './photos/segunAwe.jpg';
import lukmanoyeleke from './photos/lukmanoyeleke.jpg';
import olubiyo from './photos/olubiyo.jpg';
import olufayoAbiodun from './photos/olufayoAbiodun.jpg';
import omowonuola from './photos/omowonuola.jpg';
import sam from './photos/sam.jpg';
import sayoayeni from './photos/sayoayeni.jpg';
import sundayaluko from './photos/sundayaluko.jpg';
import taofeeklamidi from './photos/taofeeklamidi.jpg';
import tundeaworele from './photos/tundeaworele.jpg';
import waleasaaju from './photos/waleasaaju.jpg';
import waleogunleye from './photos/waleogunleye.jpg';
import titifapo from './photos/titifapo.jpg';
import idris from './photos/idrisAkintan.jpg';
import Feyikemi from './photos/feyikemi.jpg';
import alajo from './photos/alajo.jpg';
import victoadepoju from './photos/victoriaadepoju.jpg';
import adunola from './photos/adunola.jpg';
import seyiadebayo from './photos/SeyiAdebayo.jpg';


const Alumnis = () => {
  return (
    <ul class="collection">
    <li class="collection-item avatar">
    <img src={ajiwe} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Oladunni Ajiwe</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={olubiyo} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Olubiyo Osungbohun</span>
      <p>
      Position : Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={sam} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Samson Adesanmi Adeyemi</span>
      <p>Position: President <br /> About Me: Proud to be an alumni 95 set, a software engineer by trade, glad to support the 
        great things this team has embarked on.
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={titifapo} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Titilola Fapojuwo </span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Ademola Anjorin </span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={bolajoko} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Bolajoko </span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={idowuabe} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Idowu Abe</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={janetareola} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Janet Areola</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={kehindeadesina} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Kehinde Adesina</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Kehinde Afolabi </span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={lekan} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Lekan </span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={dapoOlayemi} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Oladapo Olayemi </span>
      <p>
      Position: VP <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={victoadepoju} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Victoria Adepoju</span>
      <p>
      Position: Assistant PRO <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={waleasaaju} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Ayowale Asaaju</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li><li class="collection-item avatar">
    <img src={waleogunleye} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Wale Ogunleye</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={alajo} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Yemi Alajo</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Feyikemi} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Olori Feyikemi</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={seyiadebayo} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Seyi Adebayo</span>
      <p>
      Position: Secretary <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={ilesanmifolaju} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Ilesanmi Folaju</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={omowonuola} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Eunice Omowonuola</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={segunAwe} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Segun Awe</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={sayoayeni} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Sayo Ayeni aka Abula</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Sola Olatunbosun</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Bukola Giwa</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={idowuadeleye} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Olajumoke Idowu Adeleye</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={fisayoabe} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Fisayo Abe</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={olufayoAbiodun} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Olufayo Abiodun </span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={jongboAdebayo} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Adebayo</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Yemi Ogedengbe</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={tundeaworele} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Tunde Aworele</span>
      <p>
      Position: Chief Whip <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Tolani</span>
      <p>
      Position: Member <br /> About Me: Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Breaking Limits</span>
      <p>
      Position: Member <br /> About Me: Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={funkeaguda} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Funke Aguda</span>
      <p>
      Position: Member <br /> About Me: Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Olatunde Olowoyeye</span>
      <p>
      Position: Member <br /> About Me: Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={idiatAdeyemi} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Idiat Adeyemi</span>
      <p>
      Position: Member <br /> About Me: Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>New Star</span>
      <p>
      Position: Member <br /> About Me: Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={diji} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Diji Olowookere </span>
      <p>
      Position: PRO <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={alabaoyebode} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Alaba Oyebode</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Temmytea</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Adekunle Ayeni</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Tade Oluwole</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={lukmanoyeleke} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Lukman Ademola</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={lekanawe} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Awe Olamilekan</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={idris} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Idris Akintan</span>
      <p>
      Position: Assistant PRO <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={dokun} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Dokun Owofade</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Obembe Gbenga (RIP)</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={sundayaluko} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Sunday Aluko</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={banjoRIP} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>BanjoRIP</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={taofeeklamidi} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Taofeek Lamidi</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}></span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}></span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={Tiles} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}></span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
    <li class="collection-item avatar">
    <img src={adunola} alt="sooMe" class="circle"/>
      <span class="title" style={{color:"green"}}>Adunola Oyewole</span>
      <p>
      Position: Member <br /> About Me : Check back later
      </p>
    </li>
  </ul>
  );
};

export default Alumnis;

//<i class="material-icons circle green">insert_chart</i>
