import React, { Component} from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Header from './Header';
import Dashboard from './Dashboard';
import About from './About';
import Alumnis from './Alumnis';
import Events from './Events';
import Donate from './Donate';
import Footer from './Footer';
import Projects from './Projects';
import Events2 from './Events copy';

class App extends Component {
  componentDidMount() {
    this.props.fetchUser();
    //this.props.fetchTweets();
  }
 
  render() {
    return (
      <div className="container">
        <BrowserRouter>
          <div>
            <Header />
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/alumnis" component={Alumnis} />
            <Route exact path="/events" component={Events} />
            {/* <Route exact path="/events2" component={Events2}/> */}
            <Route exact path="/donate" component={Donate} />
            <Route exact path="/about" component={About} />
            <Route exact path="/projects" component={Projects} />
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default connect(null, actions)(App);
